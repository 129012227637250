import { render, staticRenderFns } from "./CostSelector.vue?vue&type=template&id=2e3b7c54&scoped=true"
import script from "./CostSelector.vue?vue&type=script&lang=js"
export * from "./CostSelector.vue?vue&type=script&lang=js"
import style0 from "./CostSelector.vue?vue&type=style&index=0&id=2e3b7c54&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e3b7c54",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VSelect})
